<template>
  <v-container fluid>
    <!-- Loading indicator -->
    <div v-if="isLoading" class="d-flex justify-center my-4">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <span class="ml-2">Loading attorney data...</span>
    </div>

    <!-- Fees Table -->
    <v-card v-if="!isLoading || feesTableData.length > 0">
      <v-card-title>Fees by Month</v-card-title>
      <div style="overflow-x: auto; width: 100%;">
        <v-data-table
          :headers="feesHeaders"
          :items="feesTableData"
          :no-data-text="
            'No attorney fee data available for the selected period'
          "
          class="elevation-1"
          disable-sort
          hide-default-header
          hide-default-footer
        >
          <!-- Custom header for better scrolling -->
          <template v-slot:header>
            <thead>
              <tr>
                <th
                  v-for="header in feesHeaders"
                  :key="header.value"
                  :class="header.fixed ? 'fixed-column' : ''"
                >
                  {{ header.text }}
                </th>
              </tr>
            </thead>
          </template>

          <!-- Custom rows to ensure all month columns are displayed -->
          <template v-slot:body>
            <tbody>
              <tr v-for="item in feesTableData" :key="item.attorney">
                <td class="fixed-column">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ item.attorney }}</span>
                    </template>
                    <span>{{ getAttorneyFullName(item.attorney) }}</span>
                  </v-tooltip>
                </td>
                <td :class="getFeesAverageClass(item.total, item.monthCount)">
                  <a
                    @click="showFeeDetails(item.attorney, null, item.total)"
                    class="clickable-cell"
                  >
                    {{ formatCurrency(item.total) }}
                  </a>
                  <div class="monthly-average">
                    {{ formatCurrency(item.total / (item.monthCount || 1)) }}/mo
                  </div>
                </td>
                <td
                  v-for="header in feesHeaders.slice(2)"
                  :key="header.value"
                  :class="getFeesClass(item[header.value])"
                >
                  <a
                    v-if="item[header.value] > 0"
                    @click="
                      showFeeDetails(
                        item.attorney,
                        header.value,
                        item[header.value]
                      )
                    "
                    class="clickable-cell"
                  >
                    {{ formatCurrency(item[header.value]) }}
                  </a>
                  <span v-else>-</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <!-- Fee Details Dialog -->
    <v-dialog v-model="feeDetailsDialog" max-width="900px" scrollable>
      <v-card v-if="selectedFeeDetails">
        <v-card-title class="headline">
          {{ getFeeTitlePrefix() }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="mx-1">{{
                getAttorneyFullName(selectedFeeDetails.attorney)
              }}</span>
            </template>
            <span v-if="isComboAttorney(selectedFeeDetails.attorney)">
              Includes fees from:
              {{ getIncludedAttorneysText(selectedFeeDetails.attorney) }}
            </span>
          </v-tooltip>
          {{ getFeeTitleSuffix() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="feeDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-progress-circular
            v-if="selectedFeeDetails.loading"
            indeterminate
            color="primary"
            size="64"
            class="my-6 d-block mx-auto"
          ></v-progress-circular>

          <div
            v-else-if="selectedFeeDetails.error"
            class="text-center red--text"
          >
            {{ selectedFeeDetails.error }}
          </div>

          <div
            v-else-if="selectedFeeDetails.fees.length === 0"
            class="text-center"
          >
            No fee details found for this selection.
          </div>

          <template v-else>
            <v-card-subtitle class="pb-0 d-flex align-center">
              <div>{{ selectedFeeDetails.fees.length }} fees found</div>
              <v-spacer></v-spacer>
              <div class="subtitle-1 font-weight-bold">
                Total: {{ formatCurrency(calculateFeesTotal()) }}
              </div>
            </v-card-subtitle>

            <v-text-field
              v-model="feeDetailsSearch"
              label="Search fees"
              prepend-icon="mdi-magnify"
              clearable
              hide-details
              class="mb-4"
            ></v-text-field>

            <v-data-table
              :headers="feeDetailsHeaders"
              :items="selectedFeeDetails.fees"
              :items-per-page="10"
              sort-by="feeDate"
              sort-desc
              :search="feeDetailsSearch"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20, -1],
                'items-per-page-text': 'Rows per page:',
              }"
              class="elevation-1"
            >
              <template v-slot:item.fee="{ item }">
                {{ formatCurrency(item.fee) }}
              </template>
              <template v-slot:item.feeDate="{ item }">
                {{ formatDate(item.feeDate) }}
              </template>
              <template v-slot:item.attorney="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.attorney }}</span>
                  </template>
                  <span>{{ getAttorneyFullName(item.attorney) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="success"
            text
            :disabled="
              !selectedFeeDetails ||
                !selectedFeeDetails.fees ||
                selectedFeeDetails.fees.length === 0
            "
            @click="exportFeesToCSV"
          >
            <v-icon left>mdi-file-export</v-icon>
            Export to CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="feeDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrgStaffAttorneyStats",
  data() {
    return {
      // Raw data from GraphQL
      feesRaw: [],

      // Processed table data/headers
      feesHeaders: [],
      feesTableData: [],

      // Simple loading indicator
      loadingQueries: 0,
      totalQueries: 1,
      isRefreshing: false,

      // Store the imitated user's data
      imitatedUserData: null,

      // Store organization data
      organization: null,

      // Data for showing fee details
      selectedFeeDetails: null,
      feeDetailsDialog: false,
      feeDetailsHeaders: [
        { text: "Case ID", value: "caseId", width: "100px" },
        { text: "Case Number", value: "caseNumber", width: "180px" },
        { text: "Client Name", value: "clientName", width: "200px" },
        { text: "Fee", value: "fee", width: "120px", align: "right" },
        { text: "Fee Date", value: "feeDate", width: "120px" },
        { text: "Attorney", value: "attorney", width: "150px" },
      ],
      feeDetailsSearch: "",

      // Loading state
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters([
      "startDate",
      "endDate",
      "selectedTeam",
      "currentUser",
      "imitatedUser",
    ]),

    // Determine if the current user is an admin
    isAdmin() {
      if (!this.currentUser || !this.currentUser.roles) return false;
      return this.currentUser.roles.some((role) => role.name === "admin");
    },

    // Get the current user's team ID - if imitated user is set, use their team instead
    currentUserTeamId() {
      // If we have an imitated user and the imitated user data is loaded
      if (
        this.imitatedUser &&
        this.imitatedUserData &&
        this.imitatedUserData.teams &&
        this.imitatedUserData.teams.length > 0
      ) {
        return this.imitatedUserData.teams[0].id;
      }

      // Otherwise fall back to current user's team
      if (
        !this.currentUser ||
        !this.currentUser.teams ||
        this.currentUser.teams.length === 0
      ) {
        return null;
      }
      return this.currentUser.teams[0].id;
    },
  },
  watch: {
    // Watch for changes to the selected team
    selectedTeam: {
      immediate: true,
      handler(newTeamId, oldTeamId) {
        // Only update if it's actually changed to prevent loops
        if (newTeamId !== oldTeamId) {
          this.refetchQueries();
        }
      },
    },

    // Watch for changes to imitatedUser and load the user's data
    imitatedUser: {
      immediate: true,
      handler(newImitatedUser, oldImitatedUser) {
        // Only process if it actually changed
        if (newImitatedUser !== oldImitatedUser) {
          this.clearComponentData();

          if (newImitatedUser) {
            this.fetchImitatedUserData();
          } else {
            this.imitatedUserData = null;
            // If imitated user is cleared, reset to current user's team
            if (this.currentUserTeamId) {
              // Use nextTick to avoid immediate loop
              this.$nextTick(() => {
                this.setSelectedTeam(this.currentUserTeamId);
              });
            }
          }
        }
      },
    },

    // Watch for changes to the currentUser
    currentUser: {
      immediate: true,
      deep: true,
      handler(newUser, oldUser) {
        // Need to ensure we're not responding to the same user reference
        const newId = newUser ? newUser.id : null;
        const oldId = oldUser ? oldUser.id : null;

        if (newId !== oldId && newUser) {
          this.clearComponentData();

          if (newUser.teams && newUser.teams.length > 0) {
            // Using nextTick to avoid immediate reactions
            this.$nextTick(() => {
              this.setSelectedTeam(newUser.teams[0].id);
            });
          }
        }
      },
    },
  },
  apollo: {
    // Fees Query for Attorneys
    feesInPeriodByAttorneyByMonth: {
      query: gql`
        query feesInPeriodByAttorneyByMonth(
          $startDate: String!
          $endDate: String!
        ) {
          feesInPeriodByAttorneyByMonth(
            startDate: $startDate
            endDate: $endDate
          ) {
            attorney
            monthStart
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      result({ data }) {
        if (data && data.feesInPeriodByAttorneyByMonth) {
          this.feesRaw = data.feesInPeriodByAttorneyByMonth;
          this.buildFeesTable();
        }
        this.markQueryComplete();
      },
    },
    // Query to get organization teams and users for team selection
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            teams {
              id
              name
              active
              users {
                id
                firstName
                lastName
                staffCode
                roles {
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.currentUser ? this.currentUser.organization.id : null,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      skip() {
        return !this.currentUser || !this.currentUser.organization;
      },
      result({ data }) {
        if (data && data.organization) {
          // Store the organization data for reference
          this.organization = data.organization;

          // Filter to only active teams
          this.teamOptions = data.organization.teams
            .filter((team) => team.active)
            .map((team) => ({
              id: team.id,
              name: team.name,
            }));

          // If we don't have a selected team yet but have a current user team, set it
          if (!this.selectedTeam && this.currentUserTeamId) {
            this.setSelectedTeam(this.currentUserTeamId);
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(["setSelectedTeam"]),

    // Get all attorneys to include in fee details
    getAttorneysToInclude(mainAttorney) {
      // Define attorney combinations
      const attorneyCombinations = {
        LS: ["LS", "EMR", "KNP"],
        CLP: ["CLP", "PPR"],
        ACS: ["ACS", "TRS", "RAS"],
        RCS: ["RCS", "WC", "SSD", "PL"],
      };

      return attorneyCombinations[mainAttorney] || [mainAttorney];
    },

    // Show fee details
    showFeeDetails(attorney, monthStart, total) {
      if (!total || total <= 0) return; // Don't do anything if there are no fees

      // Show loading dialog
      this.selectedFeeDetails = {
        loading: true,
        attorney,
        monthStart,
        total,
        fees: [],
      };
      this.feeDetailsDialog = true;

      // Query the individual fees
      this.$apollo
        .query({
          query: gql`
            query individualFeesInPeriodByAttorneyByMonth(
              $startDate: String!
              $endDate: String!
            ) {
              individualFeesInPeriodByAttorneyByMonth(
                startDate: $startDate
                endDate: $endDate
              ) {
                caseId
                caseNumber
                clientName
                fee
                feeDate
                attorney
              }
            }
          `,
          variables: {
            startDate: this.createMonthStartDate(monthStart),
            endDate: this.createMonthEndDate(monthStart),
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          if (data && data.individualFeesInPeriodByAttorneyByMonth) {
            // Get the list of attorneys to include in this query
            const attorneysToInclude = this.getAttorneysToInclude(attorney);

            // Filter the results by the list of attorneys and exclude CS
            const allFees = data.individualFeesInPeriodByAttorneyByMonth;
            const filteredFees = allFees.filter(
              (fee) =>
                attorneysToInclude.includes(fee.attorney) &&
                fee.attorney !== "CS"
            );

            this.selectedFeeDetails.fees = filteredFees;
            this.selectedFeeDetails.loading = false;
          }
        })
        .catch((error) => {
          console.error("Error loading fee details:", error);
          this.selectedFeeDetails.loading = false;
          this.selectedFeeDetails.error = "Failed to load fee details";
        });
    },

    // Create a date object for the start of a month (format: YYYY-MM-DD)
    createMonthStartDate(monthStart) {
      if (!monthStart) {
        // If no month specified, use the current date range
        return this.startDate;
      }
      return monthStart; // Already in YYYY-MM-DD format for the first day of the month
    },

    // Create a date object for the end of a month (format: YYYY-MM-DD)
    createMonthEndDate(monthStart) {
      if (!monthStart) {
        // If no month specified, use the current date range
        return this.endDate;
      }

      // Parse the month string
      const [year, month] = monthStart.split("-").map(Number);

      // Create a date for the last day of the month
      // Set to first day of next month, then subtract 1 day
      const lastDay = new Date(year, month, 0);

      // Format as YYYY-MM-DD
      return `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;
    },

    // Clear all component data
    clearComponentData() {
      this.feesRaw = [];
      this.feesHeaders = [];
      this.feesTableData = [];
      this.loadingQueries = 0;
    },

    markQueryComplete() {
      this.loadingQueries++;
      if (this.loadingQueries >= this.totalQueries) {
        this.isLoading = false;
      }
    },

    // Helper method to refetch all queries
    refetchQueries() {
      this.isLoading = true;
      this.loadingQueries = 0;
      if (this.$apollo.queries.feesInPeriodByAttorneyByMonth) {
        this.$apollo.queries.feesInPeriodByAttorneyByMonth.refetch();
      }
    },

    // Format a number to one decimal place
    formatAverage(total, count) {
      if (!count) return "0.0";
      return (total / count).toFixed(1);
    },

    // Format currency values for the fees table
    formatCurrency(value) {
      if (value === undefined || value === null) return "";

      // Convert to number if it's a string
      const numValue = typeof value === "string" ? parseFloat(value) : value;

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numValue);
    },

    // Format a date for display
    formatDate(dateString) {
      if (!dateString) return "";

      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(date);
    },

    // Method for fees conditional formatting
    getFeesClass(value) {
      if (value === undefined || value === null) return "";

      // Convert to number if it's a string
      const numValue = typeof value === "string" ? parseFloat(value) : value;

      if (numValue >= 180000) return "high-value";
      if (numValue >= 150000 && numValue < 180000) return "medium-value";
      return "low-value";
    },

    // Method for fees average conditional formatting
    getFeesAverageClass(total, count) {
      if (total === undefined || total === null || !count) return "";

      // Convert to number if it's a string
      const numValue = typeof total === "string" ? parseFloat(total) : total;
      const average = numValue / count;

      if (average >= 180000) return "high-value";
      if (average >= 150000 && average < 180000) return "medium-value";
      return "low-value";
    },

    // Helper to build a table with attorney as the first column
    // and each unique month as subsequent columns
    buildMonthlyTable(rawData) {
      // Ensure we have data to process
      if (!rawData || !Array.isArray(rawData) || rawData.length === 0) {
        return { headers: [], items: [] };
      }

      // Filter out CS attorney
      const filteredData = rawData.filter((item) => item.attorney !== "CS");

      // Define attorney mappings for combined stats
      const attorneyMappings = {
        EMR: "LS", // EMR fees should be included in LS stats
        KNP: "LS", // KNP fees should be included in LS stats
        PPR: "CLP", // PPR fees should be included in CLP stats
        TRS: "ACS", // TRS fees should be included in ACS stats
        RAS: "ACS", // RAS fees should be included in ACS stats
        WC: "RCS", // WC fees should be included in RCS stats
        SSD: "RCS", // SSD fees should be included in RCS stats
        PL: "RCS", // PL fees should be included in RCS stats
      };

      // 1) Collect all unique months
      const monthSet = new Set();
      filteredData.forEach((item) => {
        if (item && item.monthStart) {
          monthSet.add(item.monthStart);
        }
      });
      // Sort the months ascending; adjust if you prefer descending
      const months = Array.from(monthSet).sort();

      // 2) Build a map of attorney -> { attorney, [monthStart]: total }
      const attorneyMap = {};
      filteredData.forEach(({ attorney, monthStart, total }) => {
        if (!attorney) return; // Skip entries without attorney

        // Check if this attorney's fees should be mapped to another attorney
        const targetAttorney = attorneyMappings[attorney] || attorney;

        if (!attorneyMap[targetAttorney]) {
          attorneyMap[targetAttorney] = {
            attorney: targetAttorney,
            total: 0,
            monthCount: 0,
            // Store the original attorneys that make up this combined record
            includedAttorneys: targetAttorney === attorney ? [] : [attorney],
          };
        } else if (
          targetAttorney !== attorney &&
          !attorneyMap[targetAttorney].includedAttorneys.includes(attorney)
        ) {
          // Add to the list of included attorneys if not already there
          attorneyMap[targetAttorney].includedAttorneys.push(attorney);
        }

        // Only process if we have a valid monthStart
        if (monthStart) {
          const numericTotal =
            typeof total === "string" ? parseFloat(total) : total;

          // Store the month data (add to existing value if any)
          attorneyMap[targetAttorney][monthStart] =
            (attorneyMap[targetAttorney][monthStart] || 0) + numericTotal;

          // Update the running total
          attorneyMap[targetAttorney].total += numericTotal;

          // Only increment month count if this is the first time we're seeing this month for this attorney
          if (!attorneyMap[targetAttorney][`${monthStart}_counted`]) {
            attorneyMap[targetAttorney].monthCount += 1;
            attorneyMap[targetAttorney][`${monthStart}_counted`] = true;
          }
        }
      });

      // Clean up the temporary counting properties
      Object.values(attorneyMap).forEach((attorney) => {
        months.forEach((month) => {
          delete attorney[`${month}_counted`];
        });
      });

      // 3) Convert map to array of row objects
      const items = Object.values(attorneyMap);
      // Optional: sort attorneys alphabetically
      items.sort((a, b) => a.attorney.localeCompare(b.attorney));

      // 4) Build headers array
      const headers = [
        { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
        { text: "Total", value: "total", fixed: true, width: "120px" },
      ];

      months.forEach((m) => {
        // Format date from "YYYY-MM-DD" to "MMM YY"
        // Parse the date parts manually to avoid timezone issues
        if (!m) return; // Skip if month is null

        const parts = m.split("-");
        if (parts.length < 2) return; // Skip if format is invalid

        const [year, month] = parts.map(Number);

        // Create date using the correct month (JS months are 0-based, so no adjustment needed when parsing directly)
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthName = monthNames[month - 1]; // Adjust for 1-based month in the string
        const yearSuffix = year.toString().substr(2, 2);
        const formattedDate = `${monthName} ${yearSuffix}`;

        headers.push({
          text: formattedDate,
          value: m, // keep the original date as the value for data binding
          width: "80px",
        });
      });

      return { headers, items };
    },

    buildFeesTable() {
      if (
        !this.feesRaw ||
        !Array.isArray(this.feesRaw) ||
        this.feesRaw.length === 0
      ) {
        // Reset the table data when there's no data
        this.feesHeaders = [
          { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
          { text: "Total", value: "total", fixed: true, width: "120px" },
        ];
        this.feesTableData = [];
        return;
      }

      const { headers, items } = this.buildMonthlyTable(this.feesRaw);

      if (headers && headers.length > 0) {
        this.feesHeaders = headers;
      } else {
        this.feesHeaders = [
          { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
          { text: "Total", value: "total", fixed: true, width: "120px" },
        ];
      }

      this.feesTableData = items;
    },

    // Fetch imitated user data to get their teams
    fetchImitatedUserData() {
      if (!this.imitatedUser) return;

      this.$apollo
        .query({
          query: gql`
            query($id: ID!) {
              user(id: $id) {
                id
                firstName
                lastName
                email
                teams {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: this.imitatedUser,
          },
          fetchPolicy: "network-only", // Always fetch fresh data
        })
        .then(({ data }) => {
          if (data && data.user) {
            this.imitatedUserData = data.user;

            // If we now have imitated user data, update the team selection in Vuex
            if (
              this.imitatedUserData.teams &&
              this.imitatedUserData.teams.length > 0
            ) {
              const imitatedUserTeamId = this.imitatedUserData.teams[0].id;
              this.setSelectedTeam(imitatedUserTeamId);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching imitated user data:", error);
        });
    },

    // Reset Apollo cache
    resetApolloCache() {
      try {
        if (this.$apollo.provider.defaultClient) {
          this.$apollo.provider.defaultClient.clearStore();
          this.$apollo.provider.defaultClient.cache.reset();
        }
      } catch (e) {
        console.error("Error resetting Apollo cache:", e);
      }
    },

    // Get attorney's full name by staff code
    getAttorneyFullName(staffCode) {
      if (!this.organization || !this.organization.teams) return staffCode;

      // Look through all teams to find the attorney
      let fullName = staffCode; // Default to staff code if not found

      this.organization.teams.forEach((team) => {
        if (team.users) {
          const user = team.users.find((user) => user.staffCode === staffCode);
          if (user) {
            fullName = `${user.firstName} ${user.lastName}`;
          }
        }
      });

      return fullName;
    },

    // Helper methods for fee details dialog title
    getFeeTitlePrefix() {
      if (!this.selectedFeeDetails) return "";
      const { total } = this.selectedFeeDetails;
      return `${this.formatCurrency(total)} in Fees by `;
    },

    getFeeTitleSuffix() {
      if (!this.selectedFeeDetails) return "";
      const { monthStart } = this.selectedFeeDetails;

      if (monthStart) {
        // Format the month for display
        const [year, month] = monthStart.split("-").map(Number);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const formattedMonth = `${monthNames[month - 1]} ${year}`;
        return ` in ${formattedMonth}`;
      } else {
        return "";
      }
    },

    // Export fees to CSV
    exportFeesToCSV() {
      if (
        !this.selectedFeeDetails ||
        !this.selectedFeeDetails.fees ||
        this.selectedFeeDetails.fees.length === 0
      ) {
        return;
      }

      const fees = this.selectedFeeDetails.fees;
      const fileName = `attorney_fees_${
        this.selectedFeeDetails.attorney
      }_${new Date().toISOString().slice(0, 10)}.csv`;

      // Define the fields to include in the CSV
      const fields = [
        { key: "caseId", label: "Case ID" },
        { key: "caseNumber", label: "Case Number" },
        { key: "clientName", label: "Client Name" },
        { key: "fee", label: "Fee", formatter: this.formatCurrency },
        { key: "feeDate", label: "Fee Date", formatter: this.formatDate },
        { key: "attorney", label: "Attorney" },
      ];

      // Create CSV header row
      let csvContent =
        fields.map((field) => `"${field.label}"`).join(",") + "\n";

      // Add data rows
      fees.forEach((fee) => {
        const row = fields.map((field) => {
          // Get the value, apply formatter if specified
          let value = field.formatter
            ? field.formatter(fee[field.key])
            : fee[field.key];
          // Escape quotes and wrap in quotes
          return `"${String(value || "").replace(/"/g, '""')}"`;
        });
        csvContent += row.join(",") + "\n";
      });

      // Create a download link and trigger it
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Set link properties
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      // Add to document, click it, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Calculate the total fees
    calculateFeesTotal() {
      if (!this.selectedFeeDetails || !this.selectedFeeDetails.fees) return 0;
      return this.selectedFeeDetails.fees.reduce(
        (total, fee) => total + fee.fee,
        0
      );
    },

    // Check if the attorney is a combination of other attorneys
    isComboAttorney(attorney) {
      const attorneyCombinations = {
        LS: ["LS", "EMR", "KNP"],
        CLP: ["CLP", "PPR"],
        ACS: ["ACS", "TRS", "RAS"],
        RCS: ["RCS", "WC", "SSD", "PL"],
      };
      return (
        attorneyCombinations[attorney] &&
        attorneyCombinations[attorney].length > 1
      );
    },

    // Get the included attorneys text
    getIncludedAttorneysText(attorney) {
      const attorneyCombinations = {
        LS: ["LS", "EMR", "KNP"],
        CLP: ["CLP", "PPR"],
        ACS: ["ACS", "TRS", "RAS"],
        RCS: ["RCS", "WC", "SSD", "PL"],
      };
      if (this.isComboAttorney(attorney)) {
        return attorneyCombinations[attorney].join(", ");
      }
      return attorney;
    },
  },
  mounted() {
    // Initialize the component safely
    try {
      // Set a timeout to ensure the query only runs after component is fully initialized
      setTimeout(() => {
        this.refetchQueries();
      }, 100);
    } catch (error) {
      console.error("Error during component mount:", error);
    }
  },
  errorCaptured(err, vm, info) {
    // Log any errors that occur in child components or this component
    console.error("Error captured in OrgStaffAttorneyStats:", err, info);
    return false; // Don't propagate the error
  },
};
</script>

<style scoped>
/* Adjust spacing or widths as needed */
.mb-5 {
  margin-bottom: 20px;
}

/* Conditional formatting styles for tables */
.high-value {
  background-color: #e8f5e9; /* light green */
  color: #2e7d32; /* darker green */
  font-weight: 500;
}

.medium-value {
  background-color: #fff8e1; /* light yellow */
  color: #f57f17; /* amber/orange */
  font-weight: 500;
}

.low-value {
  background-color: #ffebee; /* light red */
  color: #c62828; /* darker red */
  font-weight: 500;
}

/* Clickable cell styling */
.clickable-cell {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  border-bottom: 1px dashed;
  padding-bottom: 1px;
}

.clickable-cell:hover {
  opacity: 0.8;
  border-bottom: 1px solid;
}

/* Fixed columns styling */
.fixed-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
  font-weight: bold;
}

/* Monthly average display */
.monthly-average {
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 2px;
}

/* Customize table cells */
::v-deep th,
::v-deep td {
  padding: 4px 8px !important;
  min-width: 80px;
  text-align: left;
  font-size: 0.9rem;
  height: auto !important;
  line-height: 1.2;
}

::v-deep th:first-child,
::v-deep td:first-child {
  text-align: left;
  min-width: 120px;
}

::v-deep th:nth-child(2),
::v-deep td:nth-child(2) {
  text-align: left;
  min-width: 120px;
}

/* Allow text to wrap in detail table cells */
::v-deep .v-data-table td {
  white-space: normal;
  max-width: 300px;
}

/* Condensed table styles */
::v-deep .v-data-table {
  line-height: 1;
}

::v-deep .v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: auto !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: auto !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-size: 0.85rem;
}
</style>
